import {
  useTranslate,
} from "@pankod/refine-core";
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from "@cubejs-client/react";
import { Col, Card, Spin, Row, Statistic } from "@pankod/refine-antd";
import { Chart, Axis, Tooltip, Geom, Coord, Legend, Label } from "bizcharts";
import moment from "moment";
// import numeral from "numeral";

// const numberFormatter = (item: any) => numeral(item).format("0,0");

const dateFormatter = (item: any) => moment(item).format("MMM YY");

const colors = ["#7DB3FF", "#49457B", "#FF7C78"];
let apiTokenPromise;

const renderChart = (Component: any, options = {}) => ({ resultSet, error } : any) =>
  (resultSet && <Component resultSet={resultSet} {...options} />) ||
  (error && error.toString()) || <div style={{ textAlign: "center", paddingTop: 30 }}><Spin /> </div>;

const DashboardItem = ({ children, title, size, height }: any) => (
  <Col span={24} lg={size}>
    <Card
      title={title}
      style={{
        marginBottom: "24px",
        marginRight: "12px",
      }}
    >
      <div style={{height: height}}>
        {children}
      </div>
    </Card>
  </Col>
);

DashboardItem.defaultProps = {
  size: 12
};

const numberRender = ({ resultSet }: any) => (
  <Row
    justify="center"
    align="middle"
    style={{
      height: "100%"
    }}
  >
    <Col>
      {resultSet.seriesNames().map((s: any) => (
        <Statistic value={resultSet.totalRow()[s.key]} />
      ))}
    </Col>
  </Row>
);


export const Performance: React.FC<any> = ({
  dateRange,
}) => {
  const t = useTranslate();
  const apiKey: any = process.env.REACT_APP_CUBEJS_API_KEY;
  const apiUrl: any = process.env.REACT_APP_CUBEJS_API_URL;
  const cubejsApi = cubejs(
    apiKey,
    { apiUrl }
  );

  let env: string = process.env.REACT_APP_ENV || 'prod';
  if(env === 'prod' || env === '') {
    env = 'prod';
  }
  const defaultFilters: any = [
    {
      member: `Events.env`,
      operator: `equals`,
      values: [env]
    },
    {
      member: `Events.appId`,
      operator: `equals`,
      values: ['allstudio']
    },
    {
      member: `Events.accountId`,
      operator: `equals`,
      values: [localStorage.getItem("accountId") || '']
    },
  ];

  
  return (
      <>
         <Row>
           
          <DashboardItem title="Video Views" size={8} height={80}>
              <QueryRenderer
                query={{
                  measures: [`Events.count`],
                  timeDimensions: [
                    {
                      dimension: `Events._timestamp`,
                      dateRange,
                    }
                  ],
                  filters: [
                    {
                      member: `Events.event_type`,
                      operator: `equals`,
                      values: ['played_second']
                    },
                    {
                      member: `Events.seconds`,
                      operator: `equals`,
                      values: [`3`]
                    },
                    ...defaultFilters
                  ]
                }}
                cubejsApi={cubejsApi}
                render={renderChart(numberRender)}
              />
            </DashboardItem>

            <DashboardItem title="Watch Time (seconds)" size={8} height={80}>
              <QueryRenderer
                query={{
                  measures: [`Events.totalWatchTime`],
                  timeDimensions: [
                    {
                      dimension: `Events._timestamp`,
                      dateRange,
                    }
                  ],
                  filters: [
                    {
                      member: `Events.event_type`,
                      operator: `equals`,
                      values: ['end_watch_video']
                    },
                    ...defaultFilters
                  ]
                }}
                cubejsApi={cubejsApi}
                render={renderChart(numberRender)}
              />
            </DashboardItem>
     
            <DashboardItem title="Video Clicks" size={8} height={80}>
              <QueryRenderer
                query={{
                  measures: [`Events.count`],
                  timeDimensions: [
                    {
                      dimension: `Events._timestamp`,
                      dateRange,
                    }
                  ],
                  filters: [
                    {
                      member: `Events.event_type`,
                      operator: `equals`,
                      values: ['full_screen']
                    },
                    ...defaultFilters
                  ]
                }}
                cubejsApi={cubejsApi}
                render={renderChart(numberRender)}
              />
            </DashboardItem>
    
            <DashboardItem title="Product/CTA Clicks" size={8} height={80}>
              <QueryRenderer
                query={{
                  measures: [`Events.count`],
                  timeDimensions: [
                    {
                      dimension: `Events._timestamp`,
                      dateRange,
                    }
                  ],
                  filters: [
                    {
                      member: `Events.event_type`,
                      operator: `equals`,
                      values: ['cta_click', 'open_product_details_overlay']
                    },
                    ...defaultFilters
                  ]
                }}
                cubejsApi={cubejsApi}
                render={renderChart(numberRender)}
              />
            </DashboardItem>

            <DashboardItem title="Add to Carts" size={8} height={80}>
              <QueryRenderer
                query={{
                  measures: [`Events.count`],
                  timeDimensions: [
                    {
                      dimension: `Events._timestamp`,
                      dateRange,
                    }
                  ],
                  filters: [
                    {
                      member: `Events.event_type`,
                      operator: `equals`,
                      values: ['cta_click_success', 'addtocart_click']
                    },
                    ...defaultFilters
                  ]
                }}
                cubejsApi={cubejsApi}
                render={renderChart(numberRender)}
              />
            </DashboardItem>

            <DashboardItem title="View Product Clicks" size={8} height={80}>
              <QueryRenderer
                query={{
                  measures: [`Events.count`],
                  timeDimensions: [
                    {
                      dimension: `Events._timestamp`,
                      dateRange,
                    }
                  ],
                  filters: [
                    {
                      member: `Events.event_type`,
                      operator: `equals`,
                      values: ['view_product_details']
                    },
                    ...defaultFilters
                  ]
                }}
                cubejsApi={cubejsApi}
                render={renderChart(numberRender)}
              />
            </DashboardItem>

            <DashboardItem title="Total Visitors" size={8} height={80}>
              <QueryRenderer
                query={{
                  measures: [`Events.activeSessions`],
                  timeDimensions: [
                    {
                      dimension: `Events._timestamp`,
                      dateRange,
                    }
                  ],
                  filters: [
                    ...defaultFilters
                  ]
                }}
                cubejsApi={cubejsApi}
                render={renderChart(numberRender)}
              />
            </DashboardItem>

            <DashboardItem title="Watch Time per Visitor" size={8} height={80}>
              <QueryRenderer
                query={{
                  measures: [`Events.watchTimePerSession`],
                  timeDimensions: [
                    {
                      dimension: `Events._timestamp`,
                      dateRange,
                    }
                  ],
                  filters: [
                    ...defaultFilters
                  ]
                }}
                cubejsApi={cubejsApi}
                render={renderChart(numberRender)}
              />
            </DashboardItem>
   
            </Row>
      </>
  );
};