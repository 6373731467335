import React, { useState, useContext } from "react";
import {
    useTitle,
    ITreeMenu,
    CanAccess,
    useRouterContext,
    useMenu,
    useNavigation,
} from "@pankod/refine-core";
import { AntdLayout, Menu, Grid, Icons, useSimpleList } from "@pankod/refine-antd";
import { antLayoutSider, antLayoutSiderMobile } from "./styles";

import { AccountSelect } from "components/select";
import { AccountContext } from "context/account";
import { IChannel } from "interfaces";
import useLocation from "@pankod/refine-react-router-v6";

export const CustomSider: React.FC = () => {
    const [account] = useContext(AccountContext);
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const { Link } = useRouterContext();
    const Title = useTitle();
    const { SubMenu } = Menu;
    const { menuItems, selectedKey } = useMenu();
    const location = useLocation.useLocation();
    const { push } = useNavigation();

    let finalMenuItems: ITreeMenu[] = menuItems;

    const { listProps } = useSimpleList<
    IChannel
    >({
        pagination: {
            pageSize: 5,
        },
        resource: 'channels',
    });

    const storefrontList = useSimpleList<
    IChannel
    >({
        pagination: {
            pageSize: 5,
        },
        resource: 'storefronts',
        dataProviderName: 'sf',
    });

    if(account && account !== '') {
        const channelMenu = { ...menuItems[1] };
        channelMenu.label = 'Channels';
        channelMenu.key = '/channels';
        channelMenu.route = '';
        channelMenu.name = 'channels';
        channelMenu.icon = <Icons.VideoCameraFilled />
        channelMenu.children = [];
        listProps?.dataSource?.map((item: any) => {
            const channelMenuItem = { ...menuItems[1] };
            channelMenuItem.label = item.title;
            channelMenuItem.key = `ch${item.id}`;
            channelMenuItem.route = `/channels/show/${item.id}`;
            if(location.pathname === '/undefined' || location.pathname === '/channels') {
                push(`/channels/show/${item.id}`);
            }
            channelMenuItem.name = 'channel';
            channelMenuItem.parentName = 'channels';
            channelMenu.children.push(channelMenuItem);
            return true;
        });

        const storesMenu = { ...menuItems[1] };
        storesMenu.label = 'Stores';
        storesMenu.key = '/stores';
        storesMenu.route = '';
        storesMenu.name = 'stores';
        storesMenu.icon = <Icons.ShopFilled />
        storesMenu.children = [];
        storefrontList.listProps?.dataSource?.map((item: any) => {
            const sfMenuItem = { ...menuItems[1] };
            sfMenuItem.label = item.businessName;
            sfMenuItem.key = `sf${item.id}`;
            sfMenuItem.route = `/storefronts/show/${item.id}`;
            sfMenuItem.icon = <Icons.ShopOutlined />
            // if(location.pathname === '/undefined') {
            //     push(`/channels/show/${item.id}`);
            // }
            sfMenuItem.name = 'store';
            sfMenuItem.parentName = 'stores';
            storesMenu.children.push(sfMenuItem);
            return true;
        });
        const sfMenuItem = { ...menuItems[2] };
        sfMenuItem.label = 'Create Store';
        sfMenuItem.key = `sf-create`;
        sfMenuItem.route = `/stores/create`;
        sfMenuItem.icon = <Icons.PlusCircleOutlined />
        // if(location.pathname === '/undefined') {
        //     push(`/channels/show/${item.id}`);
        // }
        sfMenuItem.name = 'create-store';
        sfMenuItem.parentName = 'stores';
        storesMenu.children.push(sfMenuItem);
    
        finalMenuItems = [
            menuItems[0],
            channelMenu,
            storesMenu,
        ];
    }

    console.log(finalMenuItems);

    const breakpoint = Grid.useBreakpoint();

    const isMobile =
        typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;

    const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
        return tree.map((item: ITreeMenu) => {
            const { icon, label, route, name, children, parentName } = item;

            if (children.length > 0) {
                return (
                    <SubMenu
                        key={route}
                        icon={icon ?? <Icons.UnorderedListOutlined />}
                        title={label}
                    >
                        {renderTreeView(children, selectedKey)}
                    </SubMenu>
                );
            }
            const isSelected = route === selectedKey;
            const isRoute = !(
                parentName !== undefined && children.length === 0
            );
            return (
                <CanAccess
                    key={route}
                    resource={name.toLowerCase()}
                    action="list"
                    params={{ resource: item }}
                >
                    <Menu.Item
                        key={route}
                        style={{
                            fontWeight: isSelected ? "bold" : "normal",
                        }}
                        icon={
                            icon ?? (isRoute && <Icons.UnorderedListOutlined />)
                        }
                    >
                        <Link to={route}>{label}</Link>
                        {!collapsed && isSelected && (
                            <div className="ant-menu-tree-arrow" />
                        )}
                    </Menu.Item>
                </CanAccess>
            );
        });
    };

    return (
        <AntdLayout.Sider
            collapsible
            collapsedWidth={isMobile ? 0 : 80}
            collapsed={collapsed}
            breakpoint="lg"
            onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
            style={isMobile ? antLayoutSiderMobile : antLayoutSider}
        >
            {Title && <Title collapsed={collapsed} />}
            <Menu
                selectedKeys={[selectedKey]}
                mode="inline"
                onClick={() => {
                    if (!breakpoint.lg) {
                        setCollapsed(true);
                    }
                }}
            >
                <Menu.Item key={"/"} icon={<Icons.UserSwitchOutlined />}>
                    <AccountSelect />
                </Menu.Item>
                {renderTreeView(finalMenuItems, selectedKey)}
            </Menu>
        </AntdLayout.Sider>
    );
};
