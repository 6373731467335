import { List, Table, TextField, useTable,
  Image, Button, Icons, Row, Col, Form, Input, Spin } from "@pankod/refine-antd";
import { CrudFilters, useApiUrl, useNotification, useDataProvider, OpenNotificationParams } from "@pankod/refine-core";
import { useState, useEffect } from "react";



export const ShopifyProductsList: React.FC<{sfId: string, shop: string}> = ({ sfId, shop }) => {
  const [productId, setProductId] = useState("");
  const [syncing, setSyncing] = useState<any>({});
  const { open } = useNotification();
  const { tableProps, searchFormProps } = useTable<IProduct>({
    resource: `shopify/sf/${sfId}/products`,
    dataProviderName: 'sf',
    initialFilter: [
      {
        field: 'shop',
        operator: 'eq',
        value: shop,
      }
    ],
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { title } = params;
      filters.push({
        field: 'shop',
        operator: 'eq',
        value: shop,
      }, {
        field: 'title',
        operator: "contains",
        value: title,
      });
      return filters;
    }
  });
  const sfApiUrl = useApiUrl('sf');
  const dataProvider = useDataProvider();

  useEffect(() => {
    console.log('syncing', syncing);
  }, [syncing])

const SyncSfProduct = async (id: any) => {
  let newsyncing: any = syncing || {};
  newsyncing[id] = 1;
  setSyncing(newsyncing);
  setProductId(id);
  const sfDp = dataProvider('sf');
  console.log('product id', id);
  if(sfDp) {
    const res: any = await sfDp.create({
      resource: `storefronts/${sfId}/products/shopify`,
      variables: {
        shopifyProductId: id,
      },
    });
    const finalsyncing = {
      ...newsyncing,
    }
    finalsyncing[id] = false;
    setSyncing(finalsyncing);
    const notifparams: OpenNotificationParams = {
      type: 'success',
      message: "Sync Successful",
      description: `${res.data.platformProduct?.title} Synced`,
      key: `product-success-${id}`,
    }
    open?.(notifparams);
    console.log('sync done', res);
  }
};


  return (
    <Row gutter={[16, 16]}>
            <Col xs={24}>
                <Form layout="inline" {...searchFormProps}>
                    <Form.Item name="title">
                        <Input 
                            placeholder="Search Title or Name"
                            prefix={<Icons.SearchOutlined />}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" type="primary">
                            Filter
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
            <Col xs={24}>
     
          <Table {...tableProps} rowKey="id">
              <Table.Column dataIndex="featuredImage" render={
                (value) => <Image src={value.url} height={96} width={96} />
              } title="Image" />
              <Table.Column dataIndex="title" title="Title" />
              <Table.Column dataIndex="onlineStorePreviewUrl"
              render={(value, record: any) => (
                <div key={`record-actions-sf-shopify-product-${record?.id}`}>
                {syncing?.[record?.id] && <Button><Spin indicator={<Icons.LoadingOutlined />} /></Button>}
                {!syncing?.[record?.id] && <Button onClick={async(e) => SyncSfProduct(record?.id)} title="Import">{!record.sync ? 'Import' : 'Re-sync'}</Button>}

                <Button href={value} target="_blank" title="Preview" style={{ marginLeft: '10px'}}><Icons.EyeOutlined /></Button>
                </div>
              )} title="Actions" />
          </Table>
      </Col>
        </Row>
  );
};

interface IProduct {
  id: string;
  title: string;
  featuredImage: object;
  priceRangeV2: object;
  onlineStorePreviewUrl: object;
  options: object;
  totalVariants: number;
  hasOnlyDefaultVariant: boolean;
  hasOutOfStockVariants: boolean;
  images: object;
  status: string;
}

interface IShopifyProduct {
  id: string,
  platform: string;
  platformId: string;
  platformProduct: object;
}
