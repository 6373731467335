import { useTranslate, useApiUrl, OnFinishParams } from "@pankod/refine-core";

import Evaporate from 'evaporate';
import sparkMD5 from 'spark-md5';
// import sha256 from 'crypto-js/sha256'
import { sha256 } from "js-sha256";
import { v4 as uuidv4 } from "uuid";

import slugify from "slugify";
import sanitize from "sanitize-filename";

import {
    Create,
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    Space,
    ButtonProps,
    Avatar,
    Typography,
    Upload,
    Grid,
    getValueFromEvent,
    Icons,
    Radio,
} from "@pankod/refine-antd";
import { useState } from "react";

const { Text } = Typography;

type CreatePhotoProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
    onFinish: any;
};

export const CreatePhoto: React.FC<CreatePhotoProps> = ({
    drawerProps,
    formProps,
    saveButtonProps,
    onFinish,
}) => {
    const t = useTranslate();
    const [prevFileName, setPrevFileName] = useState("");
    const [type, setType] = useState('short');

    const { form } = formProps;
    const apiUrl = useApiUrl('auth');
    const uploader = Evaporate.create({
        signerUrl: `${apiUrl}/signv4_upload`,
        aws_key: 'AKIAVHG6V4XY27DHQRDP',
        awsRegion: 'ap-south-1',
        awsSignatureVersion: "4",
        bucket: 'all-saas-images',
        cloudfront: true,
        computeContentMd5: true,
        cryptoMd5Method: (d) => btoa(sparkMD5.ArrayBuffer.hash(d, true)),
        cryptoHexEncodedHash256: (d) => sha256(d?.toString() || '').toString(),
      });
    const breakpoint = Grid.useBreakpoint();

    const initialPhotoInfo: any = {};
    const [photoInfo, setPhotoInfo] = useState(initialPhotoInfo);

    const GetImageInfo = (file: File) => new Promise((resolve, reject) => {
        if(photoInfo?.file === file) {
            console.log('resolving from state');
            resolve(photoInfo);
        }
        let img = document.createElement('img');
        
        img.onload = () => {
            setPhotoInfo({
                file,
                width: img.width,
                height: img.height,
            })    
            resolve(photoInfo);         
        };

        img.src = URL.createObjectURL(file);
    });

    const createPhoto = async (values: any) => {
        delete values.file;
        const response: any = await onFinish(values);
        const photoId = response.data.data.photoId;
        console.log(photoId)
        form?.resetFields();
    };
 

    return (
        <Drawer
            {...drawerProps}
            width={breakpoint.md ? "600px" : "100%"}
            bodyStyle={{ padding: 0 }}
            zIndex={1001}
            key='create-photo'
        >
            <Create resource="photos" breadcrumb={false} saveButtonProps={saveButtonProps}>
                <Form
                    {...formProps}
                    onFinish={createPhoto}
                    layout="vertical"
                >
                    <Form.Item name="s3Key" hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item name="url" hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item name="mimeType" hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item name="width" hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item name="height" hidden>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={t("photos.fields.type")}
                        name="type"
                    >
                        <Radio.Group onChange={(e) => {
                            setType(e.target.value);
                        }} defaultValue="short" buttonStyle="solid">
                            <Radio.Button value="short">Short Photo</Radio.Button>
                            <Radio.Button value="banner">Banner</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label={t("photos.fields.s3Key.label")}>
                        <Form.Item
                            name="file"
                            valuePropName="fileList"
                            getValueFromEvent={getValueFromEvent}
                            noStyle
                            rules={[
                                {
                                    required: true,
                                    message: 'Photo is Required'
                                },
                                {
                                    validator: async (_, value) => {
                                        if (!value || !value[0] || !value[0]?.originFileObj) return Promise.resolve();
                                        // console.log('validating photo');
                                        // console.log(value);
                                        // const file = value[0].originFileObj;
                                        // const vinfo: any = await GetImageInfo(file);
                                       
                                            return Promise.resolve();
                      
                                        // return Promise.reject(
                                        //     new Error("Photo duration should be less than 2 minutes."),
                                        // );
                                    },
                                },
                            ]}
                        >
                            <Upload.Dragger
                                name="file"
                                onChange={async (info) => {
                                    const newFileName = (info.fileList.length) ? info.file.name : '';
                                    if(newFileName) {
                                        const file: any = info.file.originFileObj;
                                        const vinfo: any = await GetImageInfo(file);
                                        // if (vinfo?.duration > 120) {
                                        //     return Promise.resolve();
                                        // }
                                        // const photoInfo: any = await getPhotoInfo(file);
                                        // console.log(photoInfo);
                                        form?.setFieldsValue({
                                            width: vinfo?.width,
                                            height: vinfo?.height,
                                        });
                                    }
                                    const splitFile = newFileName.split(".");
                                    splitFile.pop();
                                    const fileNameWExt = splitFile.join(".");
                                    const curTitle = form?.getFieldValue('title');
                                    if(prevFileName === curTitle || !curTitle || curTitle===undefined) {
                                        form?.setFieldsValue({
                                            title: fileNameWExt,
                                        })
                                    }
                                    setPrevFileName(fileNameWExt);
                                    return Promise.resolve();
                                }}
                                beforeUpload={async (file) => {
   
                                    // const vinfo: any = await GetImageInfo(file);
      
                                    return true;
                                }}
                                customRequest={async (req) => {
                                    console.log(`custom request`);
                                    console.log(req);
                                    const finalFile: any = req.file;
                                    const finaluploader = await uploader;
                                    const fileType = finalFile.type;
                                    const fileName = finalFile.name;
                                    const splitFile = fileName.split(".");
                                    const fileExt = splitFile.pop();
                                    const fileNameWExt = splitFile.join(".");
                          
                                    const key = `${process.env.REACT_APP_ENV}/${slugify(sanitize(fileNameWExt).toLowerCase())}_${uuidv4()}.${fileExt}`;

                                    const awskey = await finaluploader.add({
                                        name: `allstudio${type !== 'short' ? `-${type}` : ''}/${key}`,
                                        file: finalFile,
                                        notSignedHeadersAtInitiate: {
                                            'Cache-Control': `max-age=${365*24*60*60}`,
                                            'Content-Type': fileType,
                                        },
                                        xAmzHeadersAtInitiate: {
                                            'x-amz-acl': 'public-read'
                                        },
                                        complete: (xhr, awsObjectKey, stats) => {
                                            console.log(`complete ${awsObjectKey} ${JSON.stringify(stats)}`);
                                            req.onSuccess?.({
                                                s3Key: awsObjectKey,
                                            }, xhr);
                                        },
                                        progress: (p) => {
                                            console.log(`progress ${p}`);
                                            req.onProgress?.({ percent: p*100 });
                                        }
                                    });
                                    console.log('calling complete again');
          
                                    form?.setFieldsValue({
                                        url: `https://cdn.allstudio.live/${key}`,
                                        s3Key: awskey,
                                        mimeType: fileType,
                                    });
                                    console.log(form?.getFieldsValue());
                                    req.onSuccess?.(awskey);

                                    return awskey;
                                }}
                                listType="picture"
                                maxCount={1}
                                accept=".jpg,.png,.webp"
                            >
                                <Space direction="vertical" size={2}>
                                    <Avatar
                                        style={{
                                            width: "36px",
                                            height: "36px",
                                        }}
                                        icon={<Icons.UploadOutlined  style={{
                                            fontSize: 24,
                                            padding: 4,
                                        }} />}
                                        alt="Upload Photo"
                                    />
                                    <Text
                                        style={{
                                            fontWeight: 800,
                                            fontSize: "16px",
                                            marginTop: "16px",
                                        }}
                                    >
                                        {t(
                                            "photos.fields.s3Key.description",
                                        )}
                                    </Text>
                                    <ul style={{
                                        textAlign: 'left',
                                    }}>
                                    <li style={{ fontSize: "12px" }}>
                                        {t("photos.fields.s3Key.validationSize")}
                                    </li>
                                    <li style={{ fontSize: "12px" }}>
                                        {t("photos.fields.s3Key.validationFormat")}
                                    </li>
                                    <li style={{ fontSize: "12px" }}>
                                        {t("photos.fields.s3Key.validationAspectRatio")}
                                    </li>
                                    </ul>
                                </Space>
                            </Upload.Dragger>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item
                        label={t("photos.fields.title")}
                        name="title"
                        rules={[
                            {
                                required: true,
                            },
                            {
                                max: 100
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={t("photos.fields.description")}
                        name="description"
                        rules={[
                            {
                                max: 5000
                            }
                        ]}
                    >
                        <Input.TextArea rows={2} />
                    </Form.Item>

                </Form>
            </Create>
        </Drawer>
    );
};