import { useTranslate, BaseKey } from "@pankod/refine-core";

import {
    Image,
    Card,
    Icons,
    Dropdown,
    Menu,
    Typography,
    Tag,
} from "@pankod/refine-antd";

import { IPhoto } from "interfaces";

const { Paragraph } = Typography;
const { FormOutlined } = Icons;

type PhotoItemProps = {
    item: IPhoto;
    updateStock?: (changedValue: number, clickedPhoto: IPhoto) => void;
    editShow: (id?: BaseKey) => void;
};

export const PhotoItem: React.FC<PhotoItemProps> = ({
    item,
    editShow,
}) => {
    const t = useTranslate();

    return (
        <Card
            style={{
                margin: "8px",
                padding: "0",
                borderRadius: "6px", overflow: 'hidden',
                aspectRatio: 9/16,
            }}
            bodyStyle={{ width: "100%", padding: "0px"  }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                }}
            >
                <div style={{ textAlign: "center" }}>
                    <Image style={{
                        aspectRatio: 9/16,
                        objectFit: 'cover'
                    }}  preview={false} width={'100%'}
                        src={item.finalUrls ? item.finalUrls['576'] : 'https://cdn.allstudio.live/photo-poster-placeholder.png'}
                        alt={item.title}
                        fallback="data:image/gif;base64,R0lGODlhCQAQAIAAAP///wAAACH5BAEAAAEALAAAAAAJABAAAAIKjI+py+0Po5yUFQA7"
                    />
                </div>
            </div>
            <div style={{
                position: 'absolute',
                top: '0px',
                left: '0px',
                right: '0px',
                height: '33.33%',
                backgroundImage: 'linear-gradient(rgba(43, 43, 43, 0.3), rgba(43, 43, 43, 0))',
                width: '100%',
            }}></div>
            <div style={{
                position: 'absolute',
                bottom: '0px',
                left: '0px',
                right: '0px',
                height: '33.33%',
                backgroundImage: 'linear-gradient(to top, rgba(43, 43, 43, 0.5), rgba(43, 43, 43, 0))',
                width: '100%',
            }}></div>
            <div style={{ position: "absolute", top: "10px", right: "5px" }}>
                <Dropdown
                    overlay={
                        <Menu mode="vertical">
                            <Menu.Item
                                key="2"
                                style={{
                                    fontWeight: 500,
                                }}
                                icon={
                                    <FormOutlined
                                        style={{
                                            color: "green",
                                        }}
                                    />
                                }
                                onClick={() => editShow(item.id)}
                            >
                                {t("stores.buttons.editPhoto")}
                            </Menu.Item>
                        </Menu>
                    }
                    trigger={["click"]}
                >
                    <Icons.MoreOutlined
                        style={{
                            fontSize: 24,
                            color: "White",
                            height: "32px",
                            width: "32px",
                            borderRadius: "50%",
                            border: "0px",
                            backgroundColor: "rgba(17, 17, 17, 0.65)",
                            paddingTop: "4px"
                        }}
                    />
                </Dropdown>
            </div>
            <div style={{ position: "absolute", left: "8px", top: "12px" }}>
                <Tag color={item.type==='short' ? 'black' : 'purple' }>{item.type}</Tag>
            </div>
            <div style={{position: "absolute", left: "8px", right: "8px", bottom: "12px"}} >
            <Paragraph
                    ellipsis={{ rows: 2, tooltip: true }}
                    style={{
                        fontSize: "16px",
                        fontWeight: 600,
                        marginBottom: "8px",
                        color: "White"
                    }}
                >
                    {item.title}
                </Paragraph>

                    {item.shoppableType === 'cta' && <Tag color="purple">CTA Link</Tag>}
                    {item.shoppableType === 'link' && <Tag color="purple">Link</Tag>}
                    {item.shoppableType === 'products' && <Tag color="purple">{item.shoppableData.productIds.length} Product(s)</Tag>}
                    {item.shoppableType === 'addtocart' && <Tag color="purple">Add to Cart</Tag>}
              

                {/* <Paragraph
                    ellipsis={{ rows: 3, tooltip: true }}
                    style={{ marginBottom: "8px" }}
                >
                    {item.description}
                </Paragraph>
              */}
            </div>
        </Card>
    );
};