import { useTranslate, useApiUrl } from "@pankod/refine-core";

import { useEffect, useState } from "react";
import { Highlighter } from "rc-highlight";
// import {Helmet} from "react-helmet";
// import { IVideo } from "interfaces";
// import { AsPlayer } from "@allcommercehq/allstudio"


import {
    Edit,
    Drawer,
    DrawerProps,
    Form,
    FormProps,
    Input,
    // Space,
    ButtonProps,
    // Avatar,
    // Typography,
    // Upload,
    // Grid,
    // getValueFromEvent,
    Row,
    Col,
    Collapse,
    Icons,
    Tabs,
} from "@pankod/refine-antd";
import { SelectPlaylistVideos, SelectPlaylistPhotos, SortPlaylistContent,  } from "./";
const { Panel } = Collapse;

// const { Text } = Typography;

type EditPlaylistProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
};

export const EditPlaylist: React.FC<EditPlaylistProps> = ({
    drawerProps,
    formProps,
    saveButtonProps,
}) => {
    const t = useTranslate();
    const apiUrl = useApiUrl();
    const { form } = formProps;
    const [playlistId, setPlaylistId] = useState('');
    const [channelId, setChannelId] = useState('');
    const [collapseKey, setCollapseKey] = useState(['']);


    useEffect(() => {
        if(formProps?.initialValues?.id) {
            setPlaylistId(formProps?.initialValues?.id);
            setChannelId(formProps?.initialValues?.channelId);
            if(formProps?.initialValues?.items.length > 1) {
                setCollapseKey(['3']);
            } else {
                setCollapseKey(['1']);
            }
        }
    }, [formProps, playlistId]);

    const updateVideoIds = (values: any) => {
        console.log('updating selected videos', values);
         form?.setFieldsValue({
             videoIds: values,
         });
     };

     const updatePhotoIds = (values: any) => {
        console.log('updating selected photos', values);
         form?.setFieldsValue({
             photoIds: values,
         });
     };

     const updateItems = (values: any) => {
        console.log('updating sorted items', values);
         form?.setFieldsValue({
             items: values,
         });
     };

     const makeCode = (type: string, options={}) => `${type === 'slider' ? 
      `<as-carousel cid='${channelId}' pid='${playlistId}' variant="chip"></as-carousel>` 
      : 
      `<as-grid cid='${channelId}' pid='${playlistId}' variant="chip"></as-grid>`}`
    
    const scriptCode = `<script type="module" src="https://cdn.jsdelivr.net/npm/@allcommercehq/allstudio@latest/dist/allstudio/allstudio.esm.min.js"></script>
<script nomodule async src="https://cdn.jsdelivr.net/npm/@allcommercehq/allstudio@latest/dist/allstudio/allstudio.min.js"></script>`;

    return (
        <Drawer
            {...drawerProps}
            width={"100%"}
            bodyStyle={{ padding: 0 }}
            zIndex={1001}
            key={`playlist-edit-${formProps?.initialValues?.id}`}
        >
            <div style={{maxWidth: '1200px', margin: '0 auto'}}>
            <Row
                  gutter={[16, 16]}
                  style={{ background: "#fff", padding: "16px 0px" }}
              >
      
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 15, offset: 1 }} md={{ span: 15, offset: 1 }}>
                <Edit
                        saveButtonProps={saveButtonProps}
                        pageHeaderProps={{ extra: null }}
                        resource="playlists"
                        breadcrumb={false}
                        goBack={false}
                    >
                        <Form {...formProps} layout="vertical">
                            <Form.Item
                                label={t("playlists.fields.name")}
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>


                            <Collapse activeKey={collapseKey} onChange={(key) => {
                                const finalkey: any = (typeof key === "string") ? [key] : key;
                                setCollapseKey(finalkey);
                            }}>
                                <Panel header={t("playlists.fields.videos")} key="1">
                                <Form.Item
                                name="videoIds"
                                >
                                    {channelId && <SelectPlaylistVideos videoIds={form?.getFieldValue('videoIds')} onUpdate={updateVideoIds} playlistId={playlistId} channelId={channelId} />}
                                  
                                </Form.Item>

                               

                                </Panel>

                                <Panel header={t("playlists.fields.photos")} key="2">
                                <Form.Item
                                name="photoIds"
                                >
                                    {channelId && <SelectPlaylistPhotos photoIds={form?.getFieldValue('photoIds')} onUpdate={updatePhotoIds} playlistId={playlistId} channelId={channelId} />}
                                  
                                </Form.Item>

                               

                                </Panel>

                                <Panel header={t("playlists.fields.items")} key="3">
                                <Form.Item
                                name="items"
                                >
                                    {channelId && <SortPlaylistContent onUpdate={updateItems} items={form?.getFieldValue('itemsdata')} />}
                                </Form.Item>
                                </Panel>
                            </Collapse>



                            {/* <Form.Item
                                label={t("videos.fields.description")}
                                name="description"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input.TextArea rows={6} />
                            </Form.Item> */}
                        </Form>
                        </Edit>
                </Col>

                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 8, offset: 0 }} md={{ span: 8, offset: 0 }}>
                    <div className="card-container">
                        <h4><Icons.CopyFilled /> Embed</h4>
                        <Tabs defaultActiveKey="embedcarousel" type="card">
                            <Tabs.TabPane tab="Slider" key="embedcarousel">
                                <Highlighter code={makeCode('slider')}></Highlighter>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Grid" key="embedgrid">
                                <Highlighter code={makeCode('grid')}></Highlighter>
                            </Tabs.TabPane>
                        </Tabs>
                        <br/><br/>
                        <p><b>Note:</b> Below script has to be installed once inside head section before using all components.</p>
                        <Highlighter code={scriptCode}></Highlighter>
                    </div>
                </Col>
            </Row>
            </div>
     
        </Drawer>
    );
};