import { Typography,
  Image, Icons, Row, Col, Form, getValueFromEvent, Upload,     Space,
  Avatar, } from "@pankod/refine-antd";
  import { useTranslate, useApiUrl } from "@pankod/refine-core";
import { useState } from "react";
import Evaporate from 'evaporate';
import sparkMD5 from 'spark-md5';
// import sha256 from 'crypto-js/sha256'
import { sha256 } from "js-sha256";
import slugify from "slugify";
import sanitize from "sanitize-filename";
import { v4 as uuidv4 } from "uuid";
const { Text } = Typography;


export const SizeGuideSettings: React.FC<{sfId: string, onUpdate: any, settings: any}> = ({ sfId, onUpdate, settings }) => {
  // const [productId, setProductId] = useState("");
  const t = useTranslate();

  console.log('settings', settings);

  const apiUrl = useApiUrl('auth');
  const uploader = Evaporate.create({
      signerUrl: `${apiUrl}/signv4_upload`,
      aws_key: 'AKIAVHG6V4XY27DHQRDP',
      awsRegion: 'ap-south-1',
      awsSignatureVersion: "4",
      bucket: 'all-saas-images',
      cloudfront: true,
      computeContentMd5: true,
      cryptoMd5Method: (d) => btoa(sparkMD5.ArrayBuffer.hash(d, true)),
      cryptoHexEncodedHash256: (d) => sha256(d?.toString() || '').toString(),
    });

  const [defaultFile, setDefaultFile] = useState<any>({});
  // const [mobileFile, setMobileFile] = useState<Object>({});

  const [sizeGuide, setSizeGuide] = useState<Object>(settings || {
    defaultImg: null,
  });

  const initialImageInfo: any = {

  };
const [imageInfo, setImageInfo] = useState(initialImageInfo);

  const GetImageInfo = (file: File) => new Promise((resolve, reject) => {
    if(imageInfo?.file === file) {
        console.log('resolving from state');
        resolve(imageInfo);
    }
    let img = document.createElement('img');
    

    img.onload = () => {
        setImageInfo({
            file,
            width: img.width,
            height: img.height,
        })    
        resolve(imageInfo);         
     };

    img.src = URL.createObjectURL(file);
});



  return (
    <Row gutter={[16, 16]}>
            <Col xs={24}>
                <Form layout="horizontal">

                {settings?.defaultImg && <Image width={200} src={settings?.defaultImg.finalUrls['576']} />}
                    <Form.Item name="defaultImg" label={t('storefronts.fields.sizeGuideImg')}>
                        <Form.Item
                            name="defaultFile"
                            valuePropName="defaultList"
                            getValueFromEvent={getValueFromEvent}
                            noStyle
                            rules={[
                                {
                                    validator: async (_, value) => {
                                        if (!value || !value[0] || !value[0]?.originFileObj) return Promise.resolve();
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <Upload.Dragger
                                name="file"
                                onChange={async (info) => {
                                    const newFileName = (info.fileList.length) ? info.file.name : '';
                                    if(newFileName) {
                                        const file: any = info.file.originFileObj;
                                        const vinfo: any = await GetImageInfo(file);
                                        setDefaultFile({
                                          ...defaultFile,
                                          width: vinfo?.width,
                                          height: vinfo?.height,
                                        })
                                        console.log(defaultFile);
                                    }
                                    return Promise.resolve();
                                }}
                                beforeUpload={async (file) => {
                                    return true;
                                }}
                                customRequest={async (req) => {
                                    console.log(`custom request`);
                                    const finalFile: any = req.file;
                                    console.log(defaultFile);
                                    const finaluploader = await uploader;
                                    const fileType = finalFile.type;
                                    const fileName = finalFile.name;
                                    const splitFile = fileName.split(".");
                                    const fileExt = splitFile.pop();
                                    const fileNameWExt = splitFile.join(".");
                                    const typeExt = '_size_guide';
                          
                                    const key = `${process.env.REACT_APP_ENV}/${sfId}/${slugify(sanitize(fileNameWExt).toLowerCase())}_${uuidv4()}${typeExt}.${fileExt}`;

                                    const awskey = await finaluploader.add({
                                        name: `allstorefronts/${key}`,
                                        file: finalFile,
                                        notSignedHeadersAtInitiate: {
                                            'Cache-Control': `max-age=${365*24*60*60}`,
                                            'Content-Type': fileType,
                                        },
                                        xAmzHeadersAtInitiate: {
                                            'x-amz-acl': 'public-read'
                                        },
                                        complete: (xhr: any, awsObjectKey: any, stats: any) => {
                                            console.log(`complete ${awsObjectKey} ${JSON.stringify(stats)}`);
                                            req.onSuccess?.({
                                                s3Key: awsObjectKey,
                                            }, xhr);
                                        },
                                        progress: (p: any) => {
                                            console.log(`progress ${p}`);
                                            req.onProgress?.({ percent: p*100 });
                                        }
                                    });
                                    console.log('calling complete again');

                                    setSizeGuide({
                                      ...sizeGuide,
                                      defaultImg: {
                                        url: `https://sfcdn.allstudio.live/${key}`,
                                        s3Key: awskey,
                                      }
                                    });
                                    onUpdate({
                                      ...sizeGuide,
                                      defaultImg: {
                                        url: `https://sfcdn.allstudio.live/${key}`,
                                        s3Key: awskey,
                                        width: defaultFile?.width,
                                        height: defaultFile?.height,
                                        name: finalFile.name,
                                        type: finalFile.type,
                                        size: finalFile.size,
                                      }
                                    });
                                    req.onSuccess?.(awskey);

                                    return awskey;
                                }}
                                listType="picture"
                                maxCount={1}
                                accept=".jpg,.png"
                            >
                                <Space direction="vertical" size={2}>
                                    <Avatar
                                        style={{
                                            width: "36px",
                                            height: "36px",
                                        }}
                                        icon={<Icons.UploadOutlined  style={{
                                            fontSize: 24,
                                            padding: 4,
                                        }} />}
                                        alt="Upload Image"
                                    />
                                    <Text
                                        style={{
                                            fontWeight: 800,
                                            fontSize: "16px",
                                            marginTop: "16px",
                                        }}
                                    >
                                        {t(
                                            "storefronts.fields.sizeGuide.description",
                                        )}
                                    </Text>
                                    <ul style={{
                                        textAlign: 'left',
                                    }}>
                                    <li style={{ fontSize: "12px" }}>
                                        {t("storefronts.fields.sizeGuide.validationSize")}
                                    </li>
                                    <li style={{ fontSize: "12px" }}>
                                        {t("storefronts.fields.sizeGuide.validationFormat")}
                                    </li>
                                    </ul>
                                </Space>
                            </Upload.Dragger>
                        </Form.Item>
                    </Form.Item>
{/* 
                    {settings?.mobileImg && <Image width={200} src={settings?.mobileImg} />}
<Form.Item label={t("storefronts.fields.sizeGuideMobileImg")}>
  <Form.Item
      name="mobileFile"
      valuePropName="mobileList"
      getValueFromEvent={getValueFromEvent}
      noStyle
      rules={[
          {
              validator: async (_, value) => {
                  if (!value || !value[0] || !value[0]?.originFileObj) return Promise.resolve();
                  return Promise.resolve();
              },
          },
      ]}
  >
      <Upload.Dragger
          name="file"
          onChange={async (info) => {
              const newFileName = (info.fileList.length) ? info.file.name : '';
              if(newFileName) {
                  const file: any = info.file.originFileObj;
                  const vinfo: any = await GetImageInfo(file);
                  setMobileFile({
                    ...mobileFile,
                    width: vinfo?.width,
                    height: vinfo?.height,
                  })
              }
              return Promise.resolve();
          }}
          beforeUpload={async (file) => {
              return true;
          }}
          customRequest={async (req) => {
              console.log(`custom request`);
              console.log(req);
              const finalFile: any = req.file;
              const finaluploader = await uploader;
              const fileType = finalFile.type;
              const fileName = finalFile.name;
              const splitFile = fileName.split(".");
              const fileExt = splitFile.pop();
              const fileNameWExt = splitFile.join(".");
              const typeExt = 'desktop';
    
              const key = `${process.env.REACT_APP_ENV}/{sfId}/${slugify(sanitize(fileNameWExt).toLowerCase())}_${uuidv4()}${typeExt}.${fileExt}`;

              const awskey = await finaluploader.add({
                  name: `allstorefronts/${key}`,
                  file: finalFile,
                  notSignedHeadersAtInitiate: {
                      'Cache-Control': `max-age=${365*24*60*60}`,
                      'Content-Type': fileType,
                  },
                  xAmzHeadersAtInitiate: {
                      'x-amz-acl': 'public-read'
                  },
                  complete: (xhr: any, awsObjectKey: any, stats: any) => {
                      console.log(`complete ${awsObjectKey} ${JSON.stringify(stats)}`);
                      req.onSuccess?.({
                          s3Key: awsObjectKey,
                      }, xhr);
                  },
                  progress: (p: any) => {
                      console.log(`progress ${p}`);
                      req.onProgress?.({ percent: p*100 });
                  }
              });
              console.log('calling complete again');

              setSizeGuide({
                ...sizeGuide,
                desktopImg: {
                  url: `https://cdn.allstudio.live/${key}`,
                  s3Key: awskey,
                }
              })
              req.onSuccess?.(awskey);

              return awskey;
          }}
          listType="picture"
          maxCount={1}
          accept=".jpg,.png"
      >
          <Space direction="vertical" size={2}>
              <Avatar
                  style={{
                      width: "36px",
                      height: "36px",
                  }}
                  icon={<Icons.UploadOutlined  style={{
                      fontSize: 24,
                      padding: 4,
                  }} />}
                  alt="Upload Image"
              />
              <Text
                  style={{
                      fontWeight: 800,
                      fontSize: "16px",
                      marginTop: "16px",
                  }}
              >
                  {t(
                      "storefronts.fields.sizeGuide.description",
                  )}
              </Text>
              <ul style={{
                  textAlign: 'left',
              }}>
              <li style={{ fontSize: "12px" }}>
                  {t("storefronts.fields.sizeGuide.validationSize")}
              </li>
              <li style={{ fontSize: "12px" }}>
                  {t("storefronts.fields.sizeGuide.validationFormat")}
              </li>
              </ul>
          </Space>
      </Upload.Dragger>
  </Form.Item>
</Form.Item> */}
                </Form>
            </Col>
        </Row>
  );
};